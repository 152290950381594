import React from "react"

import GatsbyImage from "gatsby-image"
import { Caption } from "./components"

export default function Image(props) {
  const { caption = "", ...rest } = props

  return (
    <>
      <GatsbyImage {...rest} />
      {caption && <Caption text={caption} />}
    </>
  )
}
