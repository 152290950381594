import React from "react"
import PropTypes from "prop-types"

import { useFormik } from "formik"
import { initialValues, validationSchema } from "./schema"

import { Box, Typography } from "@material-ui/core"
import { Input, Submit } from "./components"

export default function Form(props) {
  const { loading, onSubmit, status, message } = props
  const isSuccess = status === "success"

  const { errors, getFieldProps, handleSubmit, touched, resetForm } = useFormik(
    {
      initialValues,
      validationSchema,
      onSubmit,
    }
  )

  React.useEffect(
    function resetFormOnSuccess() {
      isSuccess && resetForm()
    },
    // Skip static dep resetForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSuccess]
  )
  return (
    <form className="footer-child" noValidate onSubmit={handleSubmit}>
      {isSuccess ? (
        <Typography align="center">{message}</Typography>
      ) : (
        <>
          {status === "error" && (
            <Typography align="center" color="error" gutterBottom>
              {message}
            </Typography>
          )}
          <Box display="flex" justifyContent="center" alignItems="center">
            <Input
              disabled={!!loading}
              helperText={touched.email ? errors.email : ""}
              error={Boolean(errors.email)}
              {...getFieldProps("email")}
            />
            <Submit loading={loading} />
          </Box>
        </>
      )}
    </form>
  )
}

Form.propTypes = {
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  message: PropTypes.string,
  status: PropTypes.oneOf(["success", "error", "fetch", "unfetch"]).isRequired,
}
