import React from "react"
import PropTypes from "prop-types"

import { withStyles } from "@material-ui/core/styles"
import { Button } from "@material-ui/core"
import { CircularProgress } from "@src/components"

const StyledButton = withStyles({
  endIcon: { "&, & *": { color: "#000000" } },
  label: { color: "#000000" },
})(Button)

export default function ButtonWithLoading(props) {
  const { loading, children, ...rest } = props
  const content = loading ? <CircularProgress /> : children

  return (
    <StyledButton disabled={!!loading} {...rest}>
      {content}
    </StyledButton>
  )
}

ButtonWithLoading.defaultProps = {
  color: "primary",
  variant: "contained",
}

ButtonWithLoading.propTypes = {
  loading: PropTypes.bool,
}
