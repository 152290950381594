import React from "react"

import { ButtonWithLoading } from "@src/components"

export default function Submit(props) {
  return (
    <ButtonWithLoading type="submit" {...props}>
      Subscribe
    </ButtonWithLoading>
  )
}
