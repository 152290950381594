import React from "react"

import { Link as GatsbyLink } from "gatsby"

import { Link as MuiLink } from "@material-ui/core"

const Link = React.forwardRef(function Link(props, ref) {
  return <MuiLink component={GatsbyLink} ref={ref} {...props} />
})

export default Link
