import React from "react"

import { Typography } from "@material-ui/core"
import { StyledInput, StyledContainer } from "./components"

export default function Input(props) {
  const { error, helperText, ...rest } = props

  return (
    <StyledContainer>
      <StyledInput
        component="input"
        placeholder="Your Email Address"
        type="email"
        aria-label="email to subscribe"
        {...rest}
      />
      {error && helperText && (
        <Typography color="error">{helperText}</Typography>
      )}
    </StyledContainer>
  )
}
