import React from "react"

import { CircularProgress as MuiCircularProgress } from "@material-ui/core"

const LOADER_ICON_SIZE = 20

export default function CircularProgress(props) {
  return (
    <MuiCircularProgress size={LOADER_ICON_SIZE} color="inherit" {...props} />
  )
}
