// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cloud_logic_custom": [
        {
            "name": "commentsApi",
            "endpoint": "https://aghwgh81jb.execute-api.eu-central-1.amazonaws.com/master",
            "region": "eu-central-1"
        },
        {
            "name": "subscribes",
            "endpoint": "https://hv7cs6lh22.execute-api.eu-central-1.amazonaws.com/master",
            "region": "eu-central-1"
        }
    ]
};


export default awsmobile;
