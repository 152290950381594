import React from "react"

import { graphql, useStaticQuery } from "gatsby"

import { Box, Paper } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"

import { Icons, Copyright, Subscribe } from "./components"

const StyledToolbar = withStyles(theme => {
  const spacing = theme.spacing(5)
  return {
    root: {
      paddingTop: spacing,
      paddingBottom: spacing,
      "& > .footer-child": { paddingBottom: spacing },
    },
  }
})(Box)

const StyledFooter = withStyles(theme => {
  return {
    root: {
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[900],
    },
  }
})(Paper)

export default function Footer() {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      strapiAuthor {
        name
        linkedin
        github
        email
      }
    }
  `)
  const { name, ...rest } = data.strapiAuthor

  return (
    <StyledFooter component="footer" elevation={4} square>
      <StyledToolbar>
        <Subscribe />
        <Icons {...rest} />
        <Copyright name={name} />
      </StyledToolbar>
    </StyledFooter>
  )
}
