import React from "react"
import PropTypes from "prop-types"

import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"

import { Helmet } from "react-helmet"
import { getFullYear, getFullDate } from "@src/helpers"

export default function SEO({
  description,
  lang,
  meta,
  title,
  img,
  article = null,
}) {
  const { pathname } = useLocation()
  const { site, strapiAuthor } = useStaticQuery(query)
  const { buildTime, siteUrl } = site.siteMetadata
  const { name, description: authorDesc, logo_light } = strapiAuthor

  const metaDescription = description || authorDesc
  const metaTitle = title || name
  const image = img || logo_light
  const url = siteUrl + pathname

  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')

  const schemaOrgWebPage = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    url,
    headline: title,
    inLanguage: lang,
    mainEntityOfPage: siteUrl,
    description: metaDescription,
    name: metaTitle,
    author: {
      "@type": "Person",
      name,
    },
    copyrightHolder: {
      "@type": "Person",
      name,
    },
    copyrightYear: getFullYear(),
    creator: {
      "@type": "Person",
      name,
    },
    publisher: {
      "@type": "Person",
      name,
    },
    datePublished: "2020-07-18T10:30:00+01:00",
    dateModified: getFullDate(buildTime),
    image: {
      "@type": "ImageObject",
      url: image.url,
    },
  }

  // Initial breadcrumb list

  const itemListElement = [
    {
      "@type": "ListItem",
      item: {
        "@id": siteUrl,
        name: "Homepage",
      },
      position: 1,
    },
  ]

  let schemaArticle = null

  if (article) {
    schemaArticle = {
      "@context": "http://schema.org",
      "@type": "Article",
      author: {
        "@type": "Person",
        name,
      },
      copyrightHolder: {
        "@type": "Person",
        name,
      },
      copyrightYear: getFullYear(),
      creator: {
        "@type": "Person",
        name,
      },
      publisher: {
        "@type": "Organization",
        name,
        logo: {
          "@type": "ImageObject",
          url: logo_light.url,
        },
      },
      datePublished: new Date(article.published_at).toISOString(),
      dateModified: article.updated_at,
      description: metaDescription,
      headline: title,
      inLanguage: lang,
      url,
      name: metaTitle,
      image: {
        "@type": "ImageObject",
        url: image.url,
      },
      mainEntityOfPage: siteUrl,
    }
    // Push current blogpost into breadcrumb list
    itemListElement.push({
      "@type": "ListItem",
      item: {
        "@id": url,
        name: metaTitle,
      },
      position: 2,
    })
  }

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    description: "Breadcrumbs list",
    name: "Breadcrumbs",
    itemListElement,
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={name}
      titleTemplate={`%s | ${metaTitle}`}
      meta={[
        // Primary
        {
          name: "author",
          content: name,
        },
        {
          name: "description",
          content: metaDescription,
        },
        {
          name: "title",
          content: metaTitle,
        },
        // Google
        {
          ItemProp: "description",
          content: metaDescription,
        },
        {
          ItemProp: "name",
          content: name,
        },
        {
          ItemProp: "image",
          content: image.url,
        },
        // Open graph
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:url",
          content: url,
        },
        {
          property: "og:title",
          content: metaTitle,
        },
        {
          property: "og:image",
          content: image.url,
        },
        {
          property: "og:image:width",
          content: image?.width || 1200,
        },
        {
          property: "og:image:height",
          content: image?.height || 600,
        },
        {
          property: "og:description",
          content: metaDescription,
        },
        {
          property: "twitter:card",
          content: "summary",
        },
        {
          property: "twitter:url",
          content: url,
        },
        {
          property: "twitter:title",
          content: metaTitle,
        },
        {
          property: "twitter:description",
          content: metaDescription,
        },
        {
          property: "twitter:image",
          content: image,
        },
      ].concat(meta)}
    >
      <link rel="canonical" href={url} />
      {/* Insert schema.org data conditionally (webpage/article) + everytime (breadcrumbs) */}
      {!article && (
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgWebPage)}
        </script>
      )}
      {article && (
        <script type="application/ld+json">
          {JSON.stringify(schemaArticle)}
        </script>
      )}
      <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: "en",
  meta: [],
  description: "",
  img: { url: "", width: 0, height: 0 },
  article: null,
}

SEO.propTypes = {
  description: PropTypes.string,
  img: PropTypes.shape({
    url: PropTypes.string.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  article: PropTypes.shape({
    published_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
  }),
}

const query = graphql`
  query SeoQuery {
    site {
      siteMetadata {
        buildTime
        siteUrl
      }
    }
    strapiAuthor {
      name
      description
      logo_light {
        url
        width
        height
      }
    }
  }
`
