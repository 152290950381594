import React from "react"
import PropTypes from "prop-types"

import { Typography } from "@material-ui/core"

export default function Caption(props) {
  const { text } = props

  return (
    <Typography
      align="center"
      variant="caption"
      component="p"
      dangerouslySetInnerHTML={innerHtml(text)}
    />
  )
}

Caption.propTypes = {
  text: PropTypes.string.isRequired,
}

function innerHtml(__html) {
  return { __html }
}
