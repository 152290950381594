import { Box } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"

export default withStyles(theme => ({
  // Mimics the default input display property used by browsers for an input.
  ...theme.typography.body1,
  color: theme.palette.text.primary,
  lineHeight: "1.1876em", // Reset (19px), match the native input line-height
  boxSizing: "border-box", // Prevent padding issue with fullWidth.
  position: "relative",
  cursor: "text",
  display: "inline-flex",
  alignItems: "center",
  "&$disabled": {
    color: theme.palette.text.disabled,
    cursor: "default",
  },
}))(Box)
