import React from "react"

import { Box, Link } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import GitHubIcon from "@material-ui/icons/GitHub"
import LinkedInIcon from "@material-ui/icons/LinkedIn"
import MailIcon from "@material-ui/icons/Mail"

const useStyles = makeStyles(theme => ({
  root: { margin: theme.spacing(0, 4) },
}))

export default function Icons(props) {
  const { linkedin, github, email } = props
  const classes = useStyles()

  return (
    <Box display="flex" justifyContent="center" className="footer-child">
      <Link href={github}>
        <GitHubIcon />
      </Link>
      <Link href={linkedin}>
        <LinkedInIcon classes={classes} />
      </Link>
      <Link href={`mailto:${email}`}>
        <MailIcon />
      </Link>
    </Box>
  )
}
