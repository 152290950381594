import React from "react"

import { Form } from "./components"
import { lowerCase, errorFeedback, successFeedback } from "@utils"
import { postSubscribe, isSuccessResponse } from "./utils"

const INITIAL_STATE = { status: "UNFETCH", message: "" }
const FETCH_STATE = { status: "FETCH", message: "" }
const FEEDBACKS = {
  success: "Comment sent success!",
  error:
    "Email subscribe failed. It may be invalid, not confirmed or already subscribed.",
}

export default function Subscribe() {
  const [{ status, message }, setFeedback] = React.useState(INITIAL_STATE)
  const loading = status === "FETCH"

  const onSubmit = React.useCallback(async function onSubmit(state) {
    try {
      const { email } = state
      setFeedback(FETCH_STATE)
      // gtag.event({
      //     action: 'submit_form',
      //     category: 'Contact',
      //     label: state.message,
      // });
      const body = { email }
      const response = await postSubscribe(body)
      const successResponse = isSuccessResponse(response)

      setFeedback(
        successResponse
          ? successFeedback(response.payload)
          : errorFeedback(FEEDBACKS.error)
      )
    } catch (error) {
      console.error(error)

      setFeedback(errorFeedback(FEEDBACKS.error))
    }
  }, [])

  return (
    <Form
      onSubmit={onSubmit}
      loading={loading}
      status={lowerCase(status)}
      message={message}
    />
  )
}
