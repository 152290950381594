import { Box } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"

export default withStyles(theme => {
  const light = theme.palette.type === "light"
  const placeholder = {
    color: "currentColor",
    opacity: light ? 0.42 : 0.5,
    transition: theme.transitions.create("opacity", {
      duration: theme.transitions.duration.shorter,
    }),
  }

  const placeholderHidden = {
    opacity: "0 !important",
  }

  const placeholderVisible = {
    opacity: light ? 0.42 : 0.5,
  }

  return {
    root: {
      font: "inherit",
      letterSpacing: "inherit",
      color: "currentColor",
      padding: `${8 - 2}px 0 ${8 - 1}px`,
      border: 0,
      boxSizing: "content-box",
      background: "none",
      height: "1.1876em", // Reset (19px), match the native input line-height
      margin: 0, // Reset for Safari
      WebkitTapHighlightColor: "transparent",
      display: "block",
      // Make the flex item shrink with Firefox
      minWidth: 0,
      width: "100%", // Fix IE 11 width issue
      animationName: "mui-auto-fill-cancel",
      animationDuration: "10ms",
      "&::-webkit-input-placeholder": placeholder,
      "&::-moz-placeholder": placeholder, // Firefox 19+
      "&:-ms-input-placeholder": placeholder, // IE 11
      "&::-ms-input-placeholder": placeholder, // Edge
      "&:focus": {
        outline: 0,
      },
      // Reset Firefox invalid required input style
      "&:invalid": {
        boxShadow: "none",
      },
      "&::-webkit-search-decoration": {
        // Remove the padding when type=search.
        "-webkit-appearance": "none",
      },
      // Show and hide the placeholder logic
      "label[data-shrink=false] + $formControl &": {
        "&::-webkit-input-placeholder": placeholderHidden,
        "&::-moz-placeholder": placeholderHidden, // Firefox 19+
        "&:-ms-input-placeholder": placeholderHidden, // IE 11
        "&::-ms-input-placeholder": placeholderHidden, // Edge
        "&:focus::-webkit-input-placeholder": placeholderVisible,
        "&:focus::-moz-placeholder": placeholderVisible, // Firefox 19+
        "&:focus:-ms-input-placeholder": placeholderVisible, // IE 11
        "&:focus::-ms-input-placeholder": placeholderVisible, // Edge
      },
      "&$disabled": {
        opacity: 1, // Reset iOS opacity
      },
      "&:-webkit-autofill": {
        animationDuration: "5000s",
        animationName: "mui-auto-fill",
      },
    },
  }
})(Box)
