import React from "react"
import PropTypes from "prop-types"

import { Typography } from "@material-ui/core"

export default function Blockquote({
  children,
  variant = "h5",
  component = "h2",
}) {
  return (
    <blockquote>
      <Typography variant={variant} component={component}>
        {children}
      </Typography>
    </blockquote>
  )
}

Blockquote.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.string,
  component: PropTypes.string,
}
