import React from "react"
import PropTypes from "prop-types"

import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { getFullYear } from "@src/helpers"

const useStyles = makeStyles({ body2: { fontWeight: 700 } })

export default function Copyright({ name }) {
  const classes = useStyles()
  return (
    <Typography classes={classes} variant="body2" align="center">
      © {getFullYear()}, {name}
    </Typography>
  )
}

Copyright.propTypes = {
  name: PropTypes.string.isRequired,
}
