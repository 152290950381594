import React from "react"
import PropTypes from "prop-types"

import { Container as MUIContainer } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"

const StyledContainer = withStyles({
  root: { maxWidth: "750px", margin: "auto" },
})(MUIContainer)

export default function Container({ children, ...props }) {
  return <StyledContainer {...props}>{children}</StyledContainer>
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
}
