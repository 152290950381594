import { API } from "aws-amplify"

export { cancelRequest, parseResponse, isSuccessResponse } from "@utils"

export function postSubscribe(body) {
  return API.post("subscribes", "/subscribes", { body })
}

export function putSubscribe(email) {
  return API.put("subscribes", `/subscribes/${email}`)
}

export function deleteSubscribe(email) {
  return API.del("subscribes", `/subscribes/${email}`)
}
