import React from "react"
import PropTypes from "prop-types"

import { useStaticQuery, graphql } from "gatsby"

import { Avatar } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"

import darkLogo from "@assets/images/logo_dark_mode.svg"
import lightLogo from "@assets/images/logo_light_mode.svg"
import { Link } from "@src/components/Link"

const ENUM = { light: lightLogo, dark: darkLogo }

const StyledAvatar = withStyles(theme => ({
  root: { height: "auto", padding: theme.spacing(0.5) },
  img: { marginBottom: 0 },
}))(Avatar)

const query = graphql`
  query BrandLogoQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default function Logo({ theme }) {
  const data = useStaticQuery(query)
  const { title } = data.site.siteMetadata
  return (
    <Link to="/">
      <StyledAvatar
        src={ENUM[theme]}
        alt={`${title} brand logo.`}
        variant="square"
      />
    </Link>
  )
}

Logo.propTypes = {
  theme: PropTypes.oneOf(Object.keys(ENUM)).isRequired,
}
