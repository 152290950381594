import { API } from "aws-amplify"

export function cancelRequest(promise, message) {
  API.cancel(promise, message)
}
export function parseResponse(response) {
  return response.payload
}

export function isSuccessResponse(response) {
  const isError =
    Object.prototype.hasOwnProperty.call(response, "error") &&
    response.status === "ERROR"
  if (isError) throw new Error(response.message)

  return response
}
