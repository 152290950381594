import Amplify from "aws-amplify"
import awsConfig from "./src/aws-exports"
import { wrapPageElement as layoutWrapper } from "./src/wrappers"
Amplify.configure(
  process.env.NODE_ENV === "production"
    ? awsConfig
    : {
        API: {
          endpoints: [
            { name: "commentsApi", endpoint: process.env.COMMENTS_API_URL },
            { name: "subscribes", endpoint: process.env.COMMENTS_API_URL },
          ],
        },
      }
)

export const wrapPageElement = layoutWrapper
